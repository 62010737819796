import { useContext, useLayoutEffect, useState, useRef, useMemo } from 'react';
import CookiesContext from './CookiesContext';
import { isInBrowser } from './utils';
export default function useCookies(dependencies) {
    const cookies = useContext(CookiesContext);
    if (!cookies) {
        throw new Error('Missing <CookiesProvider>');
    }
    const initialCookies = cookies.getAll();
    const [allCookies, setCookies] = useState(initialCookies);
    const previousCookiesRef = useRef(allCookies);
    if (isInBrowser()) {
        useLayoutEffect(() => {
            function onChange() {
                const newCookies = cookies.getAll();
                if (shouldUpdate(dependencies || null, newCookies, previousCookiesRef.current)) {
                    setCookies(newCookies);
                }
                previousCookiesRef.current = newCookies;
            }
            cookies.addChangeListener(onChange);
            return () => {
                cookies.removeChangeListener(onChange);
            };
        }, [cookies]);
    }
    const setCookie = useMemo(() => cookies.set.bind(cookies), [cookies]);
    const removeCookie = useMemo(() => cookies.remove.bind(cookies), [cookies]);
    return [allCookies, setCookie, removeCookie];
}
function shouldUpdate(dependencies, newCookies, oldCookies) {
    if (!dependencies) {
        return true;
    }
    for (let dependency of dependencies) {
        if (newCookies[dependency] !== oldCookies[dependency]) {
            return true;
        }
    }
    return false;
}
